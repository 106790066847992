/*
 * Toggle creation steps:
 * 1. Check if Jira issue has Feature Toggle Enabled set to 'yes'
 * 2. Check if feature toggle is added to the remove list, NHCGUEST-5381
 * 3. Create toggle name and key in featureFlags.ts
 * 4. Create toggle in Unleash (stage and test)
 * 5. Update toggle mock for Cypress test if applicable
 * 6. Post a comment in Jira issue calling out feature toggle name and value
 * 7. Notify delivery manager about the new feature toggle.
 */
export const NHCGUEST_506 = 'recaptcha'; // DSR reCAPTCHA
export const NHCGUEST_1217 = 'NHCGUEST_1217'; // 369 Grid for My Offers Page
export const NHCGUEST_812 = 'NHCGUEST_812'; // Expiration date for Honors Cards
export const SUPPRESS_MEMBER_TIER_TRACKER = 'NHCGUEST-3518'; // Use in tandom with Member Status
export const ENABLE_ALERT_BANNER = 'NHCGUEST-3900';
export const ENABLE_INLINE_PROGRAM_BANNER = 'NHCGUEST-3938';
export const ENABLE_COVID_POLICY_UPDATE = 'NHCGUEST-3970'; // Policy update banner on the non-member homepage
export const HIDE_SECURITI_AI = 'NHCGUEST-4134'; // Hide SecutiriAI text/image on DSR page.
export const ENABLE_TIER_BENEFITS_UPDATE = 'NHCGUEST-5289'; // Show updated requirements and benefits for every Tier
export const ENABLE_MILESTONE_TRACKER = 'NHCGUEST-4456'; // Display dynamic Milestone Bonus tracker after 9 applicable nights.
export const ENABLE_STATIC_MILESTONE_TRACKER_AND_SUPPRESS_METER = 'NHCGUEST-4504'; // Display static Milestone Bonus tracker & Account page meter suppression.
export const COMPETITOR_LIST = 'NHCGUEST-4917'; // List of competitors for price match guarantee form
export const ENABLE_TIER_POINTS_EXPIRATION = 'NHCGUEST-5269'; // Enable the honors tier qual points expiration copy
export const ENABLE_2022_MILESTONE_TRACKING = 'NHCGUEST-5290'; // Enable 2022 policy logic for Milestone bonus tracking (date toggle set for January 1, 2022)
export const ENABLE_TMTP_ALT_LOGIN = 'NHCGUEST-4463'; // Enable akamai bmp alt login url
export const ENABLE_ADD_RELATIONSHIP_WITH_HILTON = 'NHCGUEST-6173'; // Enable Relationship with Hilton info at the Personal Data Request page, renewed California form(NHCGUEST-6174) and new US form(NHCGUEST-6183).
export const ENABLE_STANDALONE_POINTS_COM = 'NHCGUEST-6956'; // Redirect to Points.com storefronts instead of loading in as iframes
export const ENABLE_OPT_OUT_REQUEST_PAGE = 'NHCGUEST-7594'; // Enable Opt Out Request Page
export const UNSUPPRESS_AUTOCAMP_MYWAY_BENEFITS_TILE = 'NHCGUEST-7629'; // Unsuppress Autocamp MyWay benefits tile
export const ENABLE_LIVESMART_UPDATE = 'NHCGUEST-7625'; // Show LivSmart requirements requirements member benifits page
export const ENABLE_MARKETABILITY_CCS_CONTENT = 'NHCGUEST-7020'; // Enable Marketability CCS changes on Join form
export const UNSUPPRESS_AUTOCAMP_MYWAY_BENEFITS_DIAMOND = 'NHCGUEST-7648'; // Unsuppress Autocamp MyWay benefits for Diamond Members
export const SUPPRESS_SLH_MYWAY_BENEFITS_SECTION = 'NHCGUEST-7681'; // Suppress SLH MyWay benefits next stay section
export const AUTOCOMPLETE_LANGUAGES = 'config-ui-translate-autocomplete'; // Supported languages for autocomplete
